@use "../theme//breakpoints.scss" as bp;
@use "../theme/mixins" as mx;

.treatments-grid {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: auto auto;
    row-gap: 16px;

    @include mx.breakpoint(bp.$lxl) {
        grid-template-columns: 1fr 1fr 1fr 1fr auto;
        row-gap: 0;
    }

    .treatment-item {
        grid-area: 1 / 1 / 2 / 2;
    }

    .start-date-item {
        grid-area: 1 / 2 / 2 / 3;

        @include mx.breakpoint(bp.$lxl) {
            grid-area: 1 / 2 / 2 / 3;
        }
    }

    .status-item {
        grid-area: 2 / 1 / 3 / 2;

        @include mx.breakpoint(bp.$lxl) {
            grid-area: 1 / 3 / 2 / 4;
        }
    }

    .eyes-item {
        grid-area: 2 / 2 / 3 / 3;

        @include mx.breakpoint(bp.$lxl) {
            grid-area: 1 / 4 / 2 / 5;
        }
    }

    .remove-item {
        grid-area: 1 / 3 / 2 / 4;
        align-self: flex-end;
        transform: translateY(50%);

        @include mx.breakpoint(bp.$lxl) {
            grid-area: 1 / 5 / 2 / 6;
            align-self: center;
            transform: translateY(0);
        }
    }
}

.treatments-grid .item-head {
    @include mx.breakpoint(bp.$lxl) {
        display: none;
    }
}

.treatments-grid:first-child .item-head {
    display: block;
}

.item-display .item-head {
    font-size: 0.875rem;

    @include mx.breakpoint(bp.$lxl) {
        font-size: 1.125rem;
    }
}
